import { ROOT, DASHBOARD_MEMBER, DASHBOARD_DRIVER } from "@/api/constant";
import { handleResponse } from "@/api/handle-response";
import { requestOptions } from "@/api/request-options";

function getDashboardMember(data) {
  return fetch(ROOT + DASHBOARD_MEMBER, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function getDashboardDriver(data) {
  return fetch(ROOT + DASHBOARD_DRIVER, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

export const dashboard = {
  getDashboardMember,
  getDashboardDriver,
};
